/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import ParseContent from 'components/shared/ParseContent'
import BoxDefaultOutline from 'components/elements/BoxDefaultOutline'
// import Plaatjie from '@ubo/plaatjie'
import FormDuuf from 'components/Form'
import * as Yup from 'yup'
import uploadIcon from '../img/icons/upload.svg'
import { FormCSS } from '../styles/FormStyle'
import Hero from '../components/elements/Hero'

const Container = styled.div`
  position: relative;
`

const StyledFormDuuf = styled(FormDuuf)`
  ${FormCSS}

  .form-duuf-label-mandatory {
    display: none !important;
  }

  .form-duuf-label,
  .form-duuf-label-mandatory {
    color: rgb(213, 0, 50);
  }

  .form-duuf-file-input {
    display: none !important;
  }

  .form-duuf-submit {
    float: unset;
  }

  .form-duuf-file-button {
    font-size: 16px;
    background: #fff;

    button {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 14px;
      width: 200px;

      :after {
        content: url(${uploadIcon});
        margin-top: 5px;
      }
    }
  }
`

const ContactBox = styled(BoxDefaultOutline)`
  position: relative;
  background-color: #d8d8d8 !important;
  border: 0;

  &::after {
    border: 2px solid #fff;
    width: 50px;
    height: 141px;
    right: -10px;
    bottom: -51px;
    border: none;
  }

  @media (max-width: 991.98px) {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
`

// const ContactBoxImage = styled(Plaatjie)`
//   position: absolute !important;

//   @media (min-width: 992px) {
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//   }

//   @media (max-width: 991px) {
//     display: none !important;
//   }
// `

const ContactLink = styled.p`
  & span {
    color: ${(props) => props.theme.color.text.contrast};
    font-weight: ${(props) => props.theme.font.weight.xl};
  }

  & a {
    color: ${(props) => props.theme.color.text.main};
    text-decoration: none;
  }
`

export const pageQuery = graphql`
  query ($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      uri
      pageContact {
        headercontact {
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 2000, quality: 100)
                meta: fluid(maxWidth: 800, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }

        contact {
          bgimage {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 2000, quality: 100)
                meta: fluid(maxWidth: 800, toFormat: JPG) {
                  src
                }
              }
            }
          }
          title
          logo {
            localFile {
              publicURL
            }
          }
          address
          socials {
            icon {
              localFile {
                publicURL
              }
            }
            link {
              title
              url
            }
          }
        }
      }
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
    }

    contact: wpComponent(databaseId: { eq: 43 }) {
      socials {
        email
        phone
      }
    }
  }
`

const PageTemplate = ({
  data: {
    page: { pageContact, seo },
    contact: {
      socials: { email, phone },
    },
  },
}) => {
  return (
    <Layout subFooter>
      {seo && <SEO seo={seo} />}

      <Hero title="Contact" src={pageContact.headercontact.image} />
      <section className="px-3 px-lg-0">
        <Container className="container pb-5">
          <div className="row pb-lg-5 mt-n5 mb-5 px-lg-3 px-md-0">
            <ContactBox className="col-lg-10 mx-auto d-flex flex-column p-5 mt-n5">
              {/* <ContactBoxImage image={pageContact.contact.bgimage} /> */}
              <div className="row pt-lg-4 px-lg-4">
                {/* <div className="col-lg-6">
                  <h2>{pageContact.contact.title}</h2>
                </div> */}
              </div>
              <div className="row px-lg-4 pb-4">
                <div className="col-lg-6 mb-5 mb-lg-0 form-contact">
                  {/* <GravityForm id={1} /> */}
                  <StyledFormDuuf
                    id={1}
                    generate
                    privacyUrlPlacement="privacyverklaring"
                    privacyUrl="/privacyverklaring"
                    yupSchema={{
                      input_5: Yup.string()
                        .min(10, 'Nummer moet 10 cijfers zijn')
                        .max(10, 'Nummer moet 10 cijfers zijn')
                        .required('Is verplicht'),
                    }}
                  />
                </div>
                <div className="col-lg-6 font-size-m">
                  <img
                    src={
                      pageContact.contact.logo.localFile
                        ? pageContact.contact.logo.localFile.publicURL
                        : pageContact.contact.logo.url
                    }
                    className="mb-5"
                    alt=""
                  />
                  <ParseContent content={pageContact.contact.address} />

                  <ContactLink className="mb-0 mt-4">
                    <span>T</span> <a href={`tel:${phone}`}>{phone}</a>
                  </ContactLink>
                  <ContactLink>
                    <span>E</span> <a href={`mailto:${email}`}>{email}</a>
                  </ContactLink>
                  <div className="d-flex mt-lg-5">
                    {pageContact.contact.socials.map((edge) => {
                      return (
                        <a href={edge.link.url} className="mr-2">
                          <img src={edge.icon.localFile.publicURL} alt="" />
                        </a>
                      )
                    })}
                  </div>
                </div>
              </div>
            </ContactBox>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default PageTemplate
